import React, { Component } from "react"
import { Map, Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react"
import settings from "/posts/metadata/settings.json"
const mapStyles = {
  width: "100%",
  height: "562px",
  position: "relative",
}
const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '100%'
}
export class MapContainer extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    zoomLevel: 3,

  }
  onMarkerClick1 = (props, marker, e) =>
    this.setState({
      selectedPlace1: props,
      activeMarker1: marker,
      showingInfoWindow1: true,
      showingInfoWindow2: false,

    })

  onMarkerClick2 = (props, marker, e) =>
    this.setState({
      selectedPlace2: props,
      activeMarker2: marker,
      showingInfoWindow2: true,
      showingInfoWindow: false,
    })
  onMarkerClick3 = (props, marker, e) =>
    this.setState({
      selectedPlace3: props,
      activeMarker3: marker,
      showingInfoWindow3: true,
      showingInfoWindow: false,
    })
  onMarkerClick4 = (props, marker, e) =>
    this.setState({
      selectedPlace4: props,
      activeMarker4: marker,
      showingInfoWindow4: true,
      showingInfoWindow: false,
    })
  onMarkerClick5 = (props, marker, e) =>
    this.setState({
      selectedPlace5: props,
      activeMarker5: marker,
      showingInfoWindow5: true,
      showingInfoWindow: false,
    })
  onMarkerClick6 = (props, marker, e) =>
    this.setState({
      selectedPlace6: props,
      activeMarker6: marker,
      showingInfoWindow6: true,
      showingInfoWindow: false,
    })
  onMarkerClick7 = (props, marker, e) =>
    this.setState({
      selectedPlace7: props,
      activeMarker7: marker,
      showingInfoWindow7: true,
      showingInfoWindow: false,
    })
  onMarkerClick8 = (props, marker, e) =>
    this.setState({
      selectedPlace8: props,
      activeMarker8: marker,
      showingInfoWindow8: true,
      showingInfoWindow: false,
    })
  onMapClicked = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      })
    }
  }

  render() {
    return (
      <>
        <div className="maps-child">
          <div
            google={this.props.google}
            onClick={this.onMapClicked}
            style={mapStyles}
            zoom={2}
            containerStyle={containerStyle}
            initialCenter={{
              lat: 50.103466302068,
              lng: 25.50451050213786,

            }}
          >

            <iframe src="https://www.google.com/maps/d/embed?mid=1vlMNepqy8N9ymUCxZbWAC6eruzI" style={{ "height": "562px", "width": "100%" }}></iframe>
          </div>
        </div>

      </>
    )
  }
}
export default GoogleApiWrapper({
  apiKey: settings.MapApiKey,
})(MapContainer)
