import React, { useState, useRef } from "react"
import Layout from "../retech/components/layout/Layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons"
import MapContainer from "../retech/components/GoogleMaps/Map"
import { Card, Col, Form, Button, InputGroup } from "react-bootstrap"
import { Helmet } from "react-helmet"
import Recaptcha from 'react-google-recaptcha'
import { navigate } from 'gatsby'

import { Link, graphql } from "gatsby"
export default function ContactPage({ data }) {
  const [buttonDisabled, setButtonDisabled] = React.useState(true)
  const [isLoading, setLoading] = React.useState(false)
  const [showAll, setShowAll] = React.useState(true)
  const [showSectionContact, setSectionContact] = React.useState(false)
  const contactfirstsection = data.contactfirstsection.nodes
  const contactbenifits = data.contactbenifits.nodes
  const contactlocation = data.contactlocation.nodes
  const url = typeof window !== "undefined" ? window.location.href : ""
  const source = typeof window !== "undefined" ? window.location.href.split("/")[3] : ""
  const urlimage = typeof window !== "undefined" ? window.location.hostname : ""
  const [formState, setFormState] = useState({
    source: source,
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    priority: "",
    message: "",

  })
  const UseFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus()
    }

    return [htmlElRef, setFocus]
  }
  const [input1Val, setInput1Val] = useState("")
  const [input1Ref, setInput1Focus] = UseFocus()

  const [input2Val, setInput2Val] = useState("")
  const [show_confirm_msg, setShowConfirmMsg] = useState(false)
  const [show_Form, setShowForm] = useState(true)
  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }
  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value

    })
  }
  const handleSubmit = e => {
    let x = document.getElementById("firstname").value;
    let n = document.getElementById("message").value;
    let y = document.getElementById("lastname").value;
    let z = document.getElementById("phone").value;
    let f = document.getElementById("email").value;
    let r = document.getElementById("priority").value;

    if (x == "" || n == "" || y == "" || z == "" || f == "" || r == "") {

      e.preventDefault();
      return false;

    };

    setLoading(true);
    e.preventDefault()
    const form = e.target
    const recaptchaValue = recaptchaRef.current.getValue()

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "message", 'g-recaptcha-response': recaptchaValue, ...formState })
    })
      .then(() => { navigate(form.getAttribute('action')); setLoading(true) })
      .catch(error => alert(error));

    e.preventDefault();
  }
  const recaptchaRef = React.createRef()
  const handleConfirmMsg = e => {
    let x = document.getElementById("firstname").value;
    if (x == "") {

      return false;
    };
    let y = document.getElementById("lastname").value;
    if (y == "") {

      return false;
    };
    let z = document.getElementById("phone").value;
    if (z == "") {

      return false;
    };
    let f = document.getElementById("email").value;
    if (f == "") {

      return false;
    };

    e.preventDefault()
    setShowConfirmMsg(true)
    setShowForm(false)

  }
  var callback = function () {
    console.log('Done!!!!');
  };

  // specifying verify callback function
  var verifyCallback = function (response) {
    console.log(response);
  };
  return (
    <>
      <Layout>
        <Helmet>
          <title>Retech | Contact</title>
          <meta
            name="description"
            content={data.metadata.nodes[0].ContactMetaDescription}
          />
          <meta
            name="keywords"
            content={data.metadata.nodes[0].Contactkeywords}
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={url} />
          <meta
            property="og:title"
            content={data.metadata.nodes[0].ContactMetaTitle}
          />
          <meta
            property="og:description"
            content={data.metadata.nodes[0].ContactMetaDescription}
          />
          <meta property="og:image" content={urlimage + "/logo.svg"} />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={url} />
          <meta
            property="twitter:title"
            content={data.metadata.nodes[0].ContactMetaTitle}
          />
          <meta
            property="twitter:description"
            content={data.metadata.nodes[0].ContactMetaDescription}
          />
          <meta property="twitter:image" content={urlimage + "/logo.svg"} />
        </Helmet>
        {showAll && (
          <>
            <div className="container-fluid bg-light pt-5 contact px-0">
              <div className="container">
                <div className="col-md-12 py-5 mb-5">
                  <div className="row">
                    {contactfirstsection.map((node, index) => {
                      return (
                        <>
                          <div className="col-md-6" key={index}>
                            <h1 className="font-weight-bolder">
                              {node.ContactWidgetTitle}
                            </h1>
                            <hr></hr>
                            <p className="text-muted my-4">
                              {node.ContactWidgetintro}
                            </p>
                            <div className="d-none d-sm-block">
                              <a
                                href={node.ContactWidgetCTALink}
                                className="font-weight-bolder see-all"
                                onClick={() => {
                                  setInput1Val("")
                                  setInput2Val("")
                                  setInput1Focus()
                                }}
                              >
                                <p>{node.ContactWidgetCTAText}</p>
                                <FontAwesomeIcon
                                  className="ml-3"
                                  icon={faChevronCircleRight}
                                />{" "}
                              </a>
                            </div>
                            <div className="d-block d-sm-none">
                              {" "}
                              <Link
                                to="#"
                                className="font-weight-bolder see-all  "
                                onClick={() => {
                                  {
                                    setShowAll(x => !x)
                                    setSectionContact(true)
                                  }
                                }}
                              >
                                <p>{node.ContactWidgetCTAText}</p>
                                <FontAwesomeIcon
                                  className="ml-3"
                                  icon={faChevronCircleRight}
                                />{" "}
                              </Link>
                            </div>
                          </div>
                        </>
                      )
                    })}

                    <div className="col-md-6 form-contact d-none d-sm-block">
                      <Card className="p-5 color-retech-card">
                        <h4 className="text-white font-weight-bolder mb-3">
                          Get in Touch
                        </h4>
                        <p className="text-white">
                          <small>
                            Fill up the form our team will get back to you
                            within 24 hours
                          </small>
                        </p>


                        <form
                          onSubmit={handleSubmit}
                          name="message"
                          method="post"
                          data-netlify-recaptcha="true"
                          data-netlify="true"
                          data-netlify-honeypot="bot-field"
                          action="/thank-you"
                        >
                          <input type="hidden" name="form-name" value="message" />

                          <Form.Row className="mb-4">
                            <Col>
                              <Form.Control
                                className="py-4"
                                type="text"

                                id="source"
                                name="source"
                                onChange={handleChange}
                                value={formState.source}

                                style={{ display: "none" }}
                              />

                              <Form.Label className="font-wight-bolder text-white">
                                First Name*
                              </Form.Label>
                              <Form.Control
                                placeholder="Enter your first name"
                                id="firstname"
                                type="text"
                                name="firstname"
                                onChange={handleChange}
                                value={formState.firstname}

                                ref={input1Ref}

                              />
                            </Col>
                            <Col>
                              <Form.Label className="font-wight-bolder text-white">
                                Last Name*
                              </Form.Label>
                              <Form.Control
                                placeholder="Enter your last name"
                                id="lastname"
                                type="text"
                                name="lastname"
                                onChange={handleChange}
                                value={formState.lastname}

                              />
                            </Col>
                          </Form.Row>
                          <Form.Group controlId="formGroupPhone1">
                            <Form.Label className="font-wight-bolder text-white">
                              Phone*
                            </Form.Label>
                            <Form.Control
                              type="tel"
                              placeholder="Enter your phone"
                              id="phone"
                              name="phone"
                              onChange={handleChange}
                              value={formState.phone}

                            />
                          </Form.Group>
                          <Form.Group controlId="formGroupEmail1">
                            <Form.Label className="font-wight-bolder text-white">
                              Email*
                            </Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter your email address"
                              id="email"
                              name="email"
                              onChange={handleChange}
                              value={formState.email}

                            />
                          </Form.Group>

                          <Form.Group controlId="formGroupPriority">
                            <Form.Label className="font-wight-bolder text-white">
                              Priority*
                            </Form.Label>
                            <Form.Control
                              as="select"
                              placeholder="Enter your Priority"
                              id="priority"
                              name="priority"
                              onChange={handleChange}
                              value={formState.priority}

                            >
                              <option value="">CHOOSE *</option>
                              <option value="Sales Domestic US">
                                Sales - Domestic US
                              </option>
                              <option value="Sales Non US">
                                Sales - Non US
                              </option>
                              <option value="Information Request">
                                Information Request
                              </option>
                              <option value="Research & Development">
                                Research & Development</option>
                              <option value="Service/Aftermarket Support">
                                Service/Aftermarket Support
                              </option>
                              <option value="Other">
                                Other
                              </option>


                            </Form.Control>

                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlTextarea12">
                            <Form.Label className="font-wight-bolder text-white">
                              Message*
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              placeholder="Write you message"
                              rows={3}
                              id="message"
                              name="message"
                              onChange={handleChange}
                              value={formState.message}
                              action="/thank-you"

                            />
                          </Form.Group>
                          <Recaptcha
                            ref={recaptchaRef}
                            sitekey="6LdwPXYbAAAAAMgj5Nqj76lv39oKQB5Jtj48_9N9"
                            size="normal"
                            id="recaptcha-google"
                            onChange={() => setButtonDisabled(false)}
                          />
                          <InputGroup className="my-3">
                            <InputGroup.Append>
                              {!isLoading && <Button
                                type="submit"
                                variant="outline-secondary"
                                className="text-white border-white p-3"
                                disabled={buttonDisabled}
                              >
                                Send Message
                              </Button>
                              }
                              {isLoading && <Button
                                type="submit"
                                variant="outline-secondary"
                                className="text-white border-white p-3"
                                disabled={buttonDisabled}
                              ><i className="fa fa-spinner fa-spin"></i>
                                Sending...
                              </Button>
                              }
                            </InputGroup.Append>
                          </InputGroup>

                        </form>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 maps px-0">
                <MapContainer />
              </div>
            </div>
            <div className="container">
              <div className="col-md-12">
                <div className="row py-5 mt-5">
                  {contactbenifits.map((node, index) => {
                    return (
                      <>
                        <div className="col-md-10 mx-auto text-center mb-5 pb-4">
                          <h3 className="mb-4 font-weight-bolder">
                            {node.ContactElementWidgetTitle}
                          </h3>
                          <Link
                            to={node.ContactElementWidgetCTALink}
                            className="font-weight-bolder see-all justify-content-center"
                          >
                            <p>{node.ContactElementWidgetCTAText}</p>
                            <FontAwesomeIcon
                              className="ml-3"
                              icon={faChevronCircleRight}
                            />{" "}
                          </Link>
                        </div>
                        {node?.benifits?.length > 0 &&
                          node.benifits.map(node2 => (
                            <div className="col-md-6 mb-5">
                              <a target="_blank" href={node2.CTALink} className="text-dark">
                              <h4 className="mb-4 font-weight-bolder">
                                {node2.Title}
                              </h4>
                            </a>
                              <p className="text-muted">
                                <small> {node2.Description}</small>
                              </p>
                            </div>
                          ))}
                      </>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="container">
              <div className="col-md-12">
                <div className="row py-5">
                  <div className="col-md-10 mx-auto text-center mb-3">
                    <h3 className="mb-4 font-weight-bolder">Retech Locations</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-none d-sm-block">
                <div className="row pt-4 pb-5 mb-5 locationcareer">
                  {contactlocation.map((node, index) => {
                    return (
                      <>
                        {node?.location?.length > 0 &&
                          node.location.map(node2 => (
                            <div className="col-xl-3 col-lg-3  text-center" key={index}>
                              <img src={node2.locationImage} alt="buffalo" />
                            </div>
                          ))}
                      </>
                    )
                  })}

                  {contactlocation.map((node, index) => {
                    return (
                      <>
                        {node?.location?.length > 0 &&
                          node.location.map((node2, index) => (
                            <div
                              className="col-xl-3 col-lg-3 text-center border-right"
                              key={index}
                            >
                              <h4 className="mb-2 font-weight-lighter">
                                {node2.locationTitle}
                              </h4>
                              <p className="text-muted mb-1">
                                <small> {node2.locationAddres1}</small>
                              </p>
                              <p className="text-muted mb-1">
                                <small>{node2.locationAddres2}</small>
                              </p>
                              <p className="text-muted mb-1">
                                <small>
                                  Phone:{" "}
                                  <a href={"tel:" + node2.locationPhone}>
                                    {node2.locationPhone}
                                  </a>
                                </small>
                              </p>
                              <p className="text-muted mb-1">
                                <small>
                                  {" "}
                                  Email:{" "}
                                  <a href={"mailto:" + node2.locationEmail}>
                                    {node2.locationEmail}
                                  </a>
                                </small>
                              </p>
                              <div className="d-flex justify-content-center mt-3">
                                <a href={node2.locationCTALink} target="_blank">
                                  <h6 className="font-weight-bolder mr-2 mb-0 align-self-center Direction-text">
                                    {node2.locationCTAText}
                                  </h6>
                                </a>
                                <img src="/img/arrow.svg" alt="arrow" />
                              </div>
                            </div>
                          ))}
                      </>
                    )
                  })}
                </div>
              </div>
              <div className="col-md-12 d-block d-sm-none">
                <div className="row pt-4 pb-5 mb-5">
                  {contactlocation.map((node, index) => {
                    return (
                      <>
                        {node?.location?.length > 0 &&
                          node.location.map(node2 => (
                            <div
                              className="col-md-4 text-center border-bottom mb-4 pb-4"
                              key={index}
                            >
                              <img
                                src={node2.locationImage}
                                alt={node2.locationTitle}
                              />
                              <h4 className="mb-2 font-weight-lighter">
                                {node2.locationTitle}
                              </h4>
                              <p className="text-muted mb-1">
                                <small>{node2.locationAddres1}</small>
                              </p>
                              <p className="text-muted mb-1">
                                <small>{node2.locationAddres2}</small>
                              </p>
                              <p className="text-muted mb-1">
                                <small>Phone: {node2.locationPhone}</small>
                              </p>
                              <p className="text-muted mb-1">
                                <small> Email:{node2.locationEmail}</small>
                              </p>
                              <div className="d-flex justify-content-center mt-3">
                                <a href={node2.locationCTALink} target="_blank">
                                  <h6 className="font-weight-bolder mr-2 mb-0 align-self-center Direction-text">
                                    {node2.locationCTAText}
                                  </h6>
                                </a>

                                <img src="/img/arrow.svg" alt="arrow" />
                              </div>
                            </div>
                          ))}
                      </>
                    )
                  })}
                </div>
              </div>
            </div>
          </>
        )}

        {showSectionContact && (
          <div className="container px-0">
            <div className="col-md-6 d-block d-sm-none px-0">
              <Card className="px-4 pt-3 pb-5 color-retech-card">
                <p
                  className="bg-white px-3 py-1 ml-auto mb-4 font-weight-bold back-button"
                  onClick={() => {
                    setShowAll(x => !x)
                    setSectionContact(x => !x)
                  }}
                  onKeyDown={() => {
                    setShowAll(x => !x)
                    setSectionContact(false)
                  }}
                >
                  Back
                </p>
                <h4 className="text-white font-weight-bolder mb-3">
                  Get in Touch
                </h4>
                <p className="text-white">
                  <small>
                    Fill up the form our team will get back to you within 24
                    hours
                  </small>
                </p>
                <form
                  onSubmit={handleSubmit}
                  name="message"
                  method="post"
                  data-netlify-recaptcha="true"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="form-name" value="contact" />
                  {show_Form && (
                    <>
                      <Form.Group controlId="formGroupName">
                        <Form.Label className="font-wight-bolder text-white">
                          First Name*
                        </Form.Label>
                        <Form.Control
                          placeholder="Enter your first name "
                          id="firstname"
                          type="text"
                          name="firstname"
                          onChange={handleChange}
                          value={formState.firstname}

                        />
                      </Form.Group>
                      <Form.Group controlId="formGroupLast">
                        <Form.Label className="font-wight-bolder text-white">
                          Last Name*
                        </Form.Label>
                        <Form.Control
                          placeholder="Enter your last name "
                          id="lastname"
                          type="text"
                          name="lastname"
                          onChange={handleChange}
                          value={formState.lastname}

                        />
                      </Form.Group>
                      <Form.Group controlId="formGroupPhone2">
                        <Form.Label className="font-wight-bolder text-white">
                          Phone*
                        </Form.Label>
                        <Form.Control
                          type="tel"
                          placeholder="Enter your phone"
                          id="phone"
                          name="phone"
                          onChange={handleChange}
                          value={formState.phone}

                        />
                      </Form.Group>
                      <Form.Group controlId="formGroupEmail">
                        <Form.Label className="font-wight-bolder text-white">
                          Email*
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter your email address"
                          id="email"
                          name="email"
                          onChange={handleChange}
                          value={formState.email}

                        />
                      </Form.Group>
                      <Form.Group controlId="formGroupPriority2">
                        <Form.Label className="font-wight-bolder text-white">
                          Priority*
                        </Form.Label>
                        <Form.Control
                          as="select"
                          placeholder="Enter your Priority"
                          id="priority"
                          name="priority"
                          onChange={handleChange}
                          value={formState.priority}

                        >
                          <option value="">CHOOSE *</option>

                          <option value="Sales Domestic US">
                            Sales - Domestic US
                          </option>
                          <option value="Sales Non US">
                            Sales - Non US
                          </option>
                          <option value="Information Request">
                            Information Request
                          </option>
                          <option value="Research & Development">
                            Research & Development</option>
                          <option value="Service/Aftermarket Support
">
                            Service/Aftermarket Support
                          </option>
                          <option value="Other">
                            Other
                          </option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label className="font-wight-bolder text-white">
                          Message*
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Write you message"
                          rows={3}
                          id="message"
                          name="message"
                          onChange={handleChange}
                          value={formState.message}

                        />
                      </Form.Group>
                      <Recaptcha
                        ref={recaptchaRef}
                        sitekey="6LdwPXYbAAAAAMgj5Nqj76lv39oKQB5Jtj48_9N9"
                        size="normal"
                        id="recaptcha-google"
                        onChange={() => setButtonDisabled(false)}
                      />
                      <InputGroup className="my-3">
                        <InputGroup.Append>
                          {!isLoading && <Button
                            type="submit"
                            variant="outline-secondary"
                            className="text-white border-white p-3"
                            disabled={buttonDisabled}
                          >
                            Send Message
                          </Button>
                          }
                          {isLoading && <Button
                            type="submit"
                            variant="outline-secondary"
                            className="text-white border-white p-3"
                            disabled={buttonDisabled}
                          ><i className="fa fa-spinner fa-spin"></i>
                            Sending ...
                          </Button>
                          }

                        </InputGroup.Append>
                      </InputGroup>
                    </>
                  )}

                </form>
              </Card>
            </div>
          </div>
        )}
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    contactfirstsection: allContactJson(
      filter: { ContactWidgetTitle: { ne: null } }
    ) {
      nodes {
        ContactWidgetTitle
        ContactWidgetintro
        ContactWidgetCTAText
        ContactWidgetCTALink
      }
    }
    contactbenifits: allContactJson(
      filter: { ContactElementWidgetTitle: { ne: null } }
    ) {
      nodes {
        ContactElementWidgetCTALink
        ContactElementWidgetCTAText
        ContactElementWidgetTitle
        benifits {
          
          Description
          CTALink
          Title
        }
      }
    }
    contactlocation: allContactJson(
      filter: { location: { elemMatch: { locationTitle: { ne: null } } } }
    ) {
      nodes {
        location {
          locationAddres1
          locationAddres2
          locationCTALink
          locationCTAText
          locationEmail
          locationImage
          locationPhone
          locationTitle
        }
      }
    }
    metadata: allMetadataJson(filter: { ContactMetaTitle: { ne: null } }) {
      nodes {
        ContactMetaDescription
        ContactMetaTitle
        Contactkeywords
      }
    }
  }
`
